
    /* Базовые стили */
    :root {
        --header-height: 70px;
        /*--primary-color: #302f69;*/
        /* --primary-color: linear-gradient(#1c1e30, #090925); */
        --primary-color: linear-gradient(#fcfcfd, #e4e4ee);
        --bg-color: #1c1e30;
        --block-bg: #272742;
        --block-bg: #FFFFFF;
    }

    .admin * {
        margin:0;
        padding:0;
        box-sizing: border-box;
        font-family: 'Open Sans', sans-serif;
        font-style:normal;
        line-height: normal;
        color:#000000;
    }

    html  {
        background-color: #FFFFFF;
    }

    body.admin {
        background: var(--primary-color, #302f69);
    }

    h3 {
        padding: 14px 0px;
    }

    span {
        color: #FFFFFF;
        color: #1a1a1d;
    }

    a {
        text-decoration: none;
    }

    .admin .btn {
        cursor: pointer;
    }

    .admin .blue-btn {
        background-color: #1319c9c5;
        color: #FFFFFF;
        padding: 5px 10px;
        border-radius: 4px;
    }

    .admin .form--message {
        font-size: 18px;
        color: red;
    }


    .admin .visible {
        visibility: visible!important;
        opacity: 1!important;
        pointer-events: all!important;
    }

    .admin .hide {
        visibility: hidden!important;
        opacity: 0!important;
        pointer-events: none!important;
        display: none!important;
    }

    /* Стиль настройки платформы */

    .admin .platform-settings .themes p{
        font-size: 14px;
    }

    .admin .platform-settings .panel .form--item {
        width: 100%;
    }


    /* Шапка */

    .admin header {
        position: fixed;
        display: flex;
        background-color: #3c3a95f7;
        background-color: #252649a1;
        background-color: #1319c9c5;
        width: 100%;
        height: var(--header-height, 70px);
        top: 0;
        overflow: hidden;
        align-items: center;
        padding: 10px;
        box-shadow: 0px 4px 10px 0px rgb(2 1 67 / 30%);
        z-index: 500;
        backdrop-filter: blur(2px);
    }

    .admin .logo {
        flex-basis: 5%;
    }

    .admin .menu--wrapper {
        flex-basis: 55%;
    }

    .admin .menu--content {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .admin .menu--item {
        position: relative;
        margin: 15px;
        max-width: 150px;
        padding: 10px 5px;
        cursor: pointer;
    }

    .admin .menu--item.active {
        opacity: 1;
    }

    .admin .menu--item:after {
        content: "";
        position: absolute;
        width: 100%;
        height: 3px;
        background-color: #FFFFFF;
        left: 0;
        bottom: 5px;
        opacity:0;
        transition: opacity .2s ease;
    }

    .admin .menu--item.active:after {
        opacity: 1;
    }

    .admin .menu--item span {
        font-size: 16px;
        color: #FFFFFF;
        font-weight: 600;
    }
    .admin header .search {
        flex-basis: 20%;
    }

    .admin .search .search-content {
        display: flex;
        background-color: #3634818f;
        border-radius: 3px;
    }

    .admin .search .search-content i {
        padding: 10px;
        color: #FFFFFF;
    }

    .admin .search .search-content input {
        width: 100%;
        font-size: 14px;
        border: none;
        outline: none;
        background-color: transparent;
        color: #FFFFFF;
        outline: none;
        text-overflow: ellipsis;
    }

    .admin .search input::-webkit-input-placeholder {color:#ffffff;}
    .admin .search input::-moz-placeholder          {color:#ffffff;}/* Firefox 19+ */
    .admin .search input:-moz-placeholder           {color:#ffffff;}/* Firefox 18- */
    .admin .search input:-ms-input-placeholder      {color:#ffffff;}

    .admin header .profile--wrapper {
        flex-basis: 4%;
        margin-left:25px
    }

    .admin .profile--content {
        width:155px;
        position: relative;
        background-color: #3634818f;
        padding: 8px 10px;
        border-radius: 3px;
    }
    .admin .profile--content span {
        font-size: 16px;
        color: #FFFFFF;
        font-weight: 600;
    }

    .admin .profile--content::after {
        position: absolute;
        content: "\23F7";
        font-size: 1.3rem;
        color: #FFFFFF;
        transform: rotate(180deg);
        left: auto;
        padding: 0px 7px;
        top: 0;
        bottom: 0;
        margin: auto;
        line-height: 0.8;
    }


/*     header .notification {
        flex-basis: 2%;
    } */


    .admin header .logout {
        flex-basis: 5%;
        display: flex;
        justify-content: center;
    }

    .admin .logout i {
        color: #FFFFFF;
        font-size: 26px;
    }

    .admin body .container {
        min-height: calc(100vh - var(--header-height, 70px));
        margin-top: var(--header-height, 70px);
        display: flex;
    }
    
    .admin .form--item {
        margin: 20px 0px;
        display: flex;
        gap: 16px;
    }
    
    .admin .form--item label {
        width: 150px;
    }
    
    .admin .form--item input {
        width: 100%;
    }
    

    .admin .modal-block {
        align-items: center;
        background-color: #1c1b1b94;
        bottom: 0;
        display: flex;
        justify-content: center;
        left: 0;
        min-height: 100%;
        min-width: 100%;
        object-fit: cover;
        opacity: 0;
        pointer-events: none;
        position: fixed;
        right: 0;
        top: 0;
        transition: opacity .2s ease;
        visibility: hidden;
        z-index: 999;
      }

      .admin .popup--wrapper {
        background-color: #fff;
        padding: 15px;
        position: fixed;
        width: 800px;
      }

      .admin .popup--header {
        display: flex;
      }

      .admin .popup--header h3 {
        width: 100%;
      }

      .admin .popup--header i {
        font-size: 28px;
      }

    .admin .popup--content {
        display: flex;
        flex-direction: column;
        gap: 15px;
      }


    .admin .popup--content button {
        padding: 5px 10px;
        background-color: #484cd4;
        color: #FFFFFF;
        border: 0px;
        font-size: 18px;
        font-weight: 500;
        cursor: pointer;
    }

    .admin .popup--content button.load {
        margin: 10px 0;
    }

    .admin .popup--body {
        align-items: center;
        display: flex;
        gap: 30px;
        justify-content: space-around;
        flex-wrap: wrap;
      }

    /* Стили для сервисный сообщений */

    .service-messages--contaner {
        position: fixed;
        width: 100%;
        top: 40px;
        gap: 10px;
        display: flex;
        flex-direction: column;
        z-index: 1000;
        margin: 0 auto;
        justify-content: center;
        align-items: center;
        pointer-events: none;
    }


    .service-message {
        min-width: 220px;
        min-height: 40px;
        padding: 5px 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        pointer-events: all;
        color: #FFFFFF;
        font-size: 14px;
        font-weight: 600;
        opacity: 0;
        transform: translateY(-25px);
        transition: opacity .3s ease, transform .3s ease;
    }
    
    .service-message.msg-show {
        transform: translateY(0px);
        opacity: 1;
        z-index: 9999999;
    }
    
    .service-message.msg-hide {
        transform: translateY(-25px);
        opacity: 0;
    }
    

    .service-message.success {
        background-color: #0fc597;
    }

    .service-message.error {
        background-color: rgb(241, 90, 90);
    }


    .admin .container {
        display: flex;
        height: calc(100vh - var(--header-height, 70px));
        margin-top: var(--header-height, 70px);
    }

    /* Стили для Navbar */
    .admin .navbar--wrapper {
        display: flex;
        overflow-x: hidden;
        flex-direction: column;
        gap: 8px;
        width: 200px;
        min-width: 30px;
        background-color: #FFFFFF;
        min-height: 100%;
        transition: width .3s ease-in-out;
    }

    .admin .navbar--item {
        width: 100%;
        transition: all .3s ease;
    }

    .admin .navbar--item {
        padding: 5px 15px;
        
    }

    .admin .navbar--item.active {
        background-color: #484cd4;
        color: #FFFFFF;
        
    }


    /* Стили для контента */

    .admin content {
        display: flex;
        width: 100%;
        justify-content: center;
        padding-top: 20px;
        padding-left: 25px;
        padding-right: 25px;
    }

    .admin .content--wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
        background-color: #FFFFFF;
        padding: 10px 10px;
        border-radius: 8px;
    }

    .admin .list-head div {
        justify-content: center;
    }

    .admin .platform-groups--list {
        display: flex;
        flex-direction: column;
        gap: 15px;
    }


    .admin .platform-group--item .table, .list-head {
        align-items: center;
        display: flex;
        width: 100%;
        gap: 10px;
        padding: 10px 5px;
    }

    .admin .platform-group--item {
        min-height: 60px;
        border: 1px solid rgb(174, 174, 247);
        border-radius: 8px;
        align-items: center;
        padding: 5px;
    }


    .admin .platform-group--item .table > div {
        width: calc(100% / 6);
        display: flex;
    }

    .admin .platform-group--item .info {
        border-top: rgb(72, 72, 253) 1px dotted;
      }

    .admin .platform-group--item .info span, .admin .platform-group--item .info a {
        font-size: 12px;
        color: grey;
    }

    .admin .list-head {
        border: 1px solid transparent;
    }

    .admin .list-head > div {
        display: flex;
    }

    .admin .platform-group--item .table div:first-child {
        width: 5%;
        justify-content: center;
    }

    .admin .platform-group--item .table .ki-lessons {
        /* white-space: nowrap; */
        overflow: hidden;
        text-overflow: ellipsis;
    } 


    .admin .head {
        display: flex;
        align-items: center;
        padding: 15px 0px;
        border-bottom: var(--bg-color) 2px solid;
        border-bottom: #bebefb 1px solid;
    }
    
    .admin .head .title {
        flex-grow: 1;
    }

    .admin .head .title span {
        font-size: 20px;
        margin-left: 10px;
        color: #3985db;
        font-weight: 600;
    }

    /* Стили для таблиц */

    .admin .table .list-head .asc::after {
        content: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAANZJREFUSEvt081pAlEUhuFHsIcEVBJMmggi9mEDggXYRlZu3KWUECRN+I+C6SGIHBhBYmbmuhBczF0O33zvOe/Mrbnxqd24XwUoNVwpun9FI3zgJ2fUB/TxnrdK0UceYow5etj9KWngEy8YYPIfpAjwiC+8Yo3OGSTKp3jCDF3srwVEPiDfeD6DxPNT+QpveeURTLkHzUxFG0vU0cIiU7ct+pVSAPF+QEJXbBInJg8theWpG5wGDEjo+k0tvxYQ+djggE3pDcsCqYpS+y5yFaBUXaWoVNERTkIfGckggW4AAAAASUVORK5CYII=');
        height: 8px;
        width: 8px;
        opacity: .7;
    }

    .admin .table .list-head .desc::after {
        content: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAMVJREFUSEvtlbEJAkEQRd/VoaBiFSKiRViCibGVmJiZ2oeKXYgoKGcZOjALBnv3V2Ejd+O593beHVxF5lNl5lMEsvD/Jep5k5ts4wPfJOoCR39uAtxTJKmCAO879AokSVIEBj8AA8DAdkx0AaZqEyX4vLl1H7vgBNj7MOEIeDblahPE4A8HdYAgOXuuqKRNsAQ2gAFmQICHy5pkDwzfmRbANraFSrQCdkDdkMAkc2D9S6KUr1DOqA0kQA0UgSpU/miyUP5EL3hdHRmNCeR+AAAAAElFTkSuQmCC');
        height: 8px;
        width: 8px;
        opacity: .7;
    }

    .admin .table .tabs {
        display: flex;
        align-items: center;
        gap: 10px;
        padding: 10px 0px 0px;
        border-bottom: var(--bg-color) 2px solid;
        border-bottom: #bebefb 3px solid;
    }

    .admin .table .tabs .tab {
        cursor: pointer;
        font-size: 18px;
        padding: 5px 10px;
        border-radius: 5px 5px 0px 0px;
        border: 1px solid #bebefb;
        border-bottom: 0px solid transparent;
    }

    .admin .table .tabs .tab.active {
        background-color: #e1e1fd;
        cursor: default;
    }


    /* Стили для экрана 650 */

    @media (max-width: 650px){

        .admin .navbar--item {
            visibility: hidden;
            opacity: 0;
            pointer-events: none;
        }

        .admin .navbar--wrapper {
            width:50px;
        }
    }